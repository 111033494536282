<template>
  <VVVLayout>
    <!-- actions -->
    <MDBCard class="mb-2" id="actions">
      <MDBCardBody class="py-3 px-4 pb-2 pb-md-3">
        <div class="d-lg-flex d-block justify-content-lg-between align-items-center">
          <div class="d-md-flex d-block gap-2 py-lg-1 py-2 align-items-center">
            <template v-if="isAdminOrSuperAdmin">
              <div class="mb-2 mb-md-0" :style="distriInputStyle">
                <MDBInput v-model="search" label="Select Distributor" type="text" @keydown="handleBackspaceDistributor"
                  size="sm" />
                <MDBTable v-if="search !== '' && !selectedUserId" responsive hover border="primary"
                  class="bg-white rounded-5 overflow-auto" style="position:absolute;left:0;">
                  <tbody>
                    <tr v-if="userListLoading">
                      <td class="text-center">Loading</td>
                    </tr>
                    <template v-else>
                      <tr v-if="userList.length === 0">
                        <td class="text-center">No data found.</td>
                      </tr>
                      <template v-else>
                        <tr v-for="(user, index) in userList" :key="index" @click="selectUser(user)"
                          style="cursor:pointer;">
                          <td>{{ user.firstName + ' ' + user.lastName }}</td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </MDBTable>
              </div>
              <MDBBtn class="mb-2 mb-md-0" color="primary" size="sm" @click="syncGoogleSheet(selectedUserId)"
                :disabled="!selectedUserId || isLoading">
                <MDBIcon iconStyle="fas" icon="table" />Sync Google Sheet
              </MDBBtn>
            </template>
            <template v-if="isUser">
              <MDBBtn class="mb-2 mb-md-0" color="primary" @click="syncGoogleSheet(userDetail.id)" size="sm"
                :disabled="isLoading">
                <MDBIcon iconStyle="fas" icon="table" />
                Sync Google Sheet
              </MDBBtn>
            </template>
            <MDBBtn class="mb-2 mb-md-0" color="primary" @click="toggleAdd" size="sm"
              :disabled="isLoading || (isAdminOrSuperAdmin && !selectedUserId)">
              <MDBIcon iconStyle="fas" icon="plus" />
              New Claim
            </MDBBtn>
          </div>
          <div class="d-md-flex d-block py-lg-1 py-2 pt-sm-3 align-items-center justify-content-end">
            <div class="mb-2 mb-md-0 me-0 me-md-2" :style="assetLabelInputStyle" v-if="isAdminOrSuperAdmin">
              <MDBInput v-model="searchAssetLabel" label="Select Asset Label" type="text"
                @keydown="handleBackspaceAssetlabel" size="sm" />
              <MDBTable v-if="searchAssetLabel !== '' && !selectedAssetlabel" responsive hover border="primary"
                class="bg-white rounded-5 overflow-auto" style="position:absolute;left:0;">
                <tbody>
                  <tr v-if="assetLabelListLoading">
                    <td class="text-center">Loading</td>
                  </tr>
                  <template v-else>
                    <tr v-if="assetLabelList.length === 0">
                      <td class="text-center">Asset label not found.</td>
                    </tr>
                    <template v-else>
                      <tr v-for="(assetLabel, index) in assetLabelList" :key="index"
                        @click="selectAssetLabel(assetLabel)" style="cursor:pointer;">
                        <td>{{ assetLabel.name }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </MDBTable>
            </div>
            <div :style="contentInputWidth">
              <MDBInput class="mb-3 mb-md-0" v-model="searchFilter" label="Search Content" type="text" size="sm"
                @keydown="handleBackspaceContent" />
            </div>
            <div class="did-floating-label-content ms-0 ms-md-2 mb-2 mb-md-0">
              <select v-model="selectedFilter" class="did-floating-select">
                <option v-for="option in claimStatusFilter" :key="option.value" :value="option.value">{{ option.text
                  }}
                </option>
              </select>
              <label class="did-floating-label">Filter by Claim Status</label>
            </div>
            <MDBBtn class="mb-2 mb-md-0 ms-0 ms-md-2" v-if="isAdminOrSuperAdmin" color="primary" size="sm"
              :disabled="isLoading" @click="generateReportModal = true">
              <MDBIcon iconStyle="fas" icon="table" />
              Generate Report
            </MDBBtn>
          </div>
        </div>
      </MDBCardBody>
    </MDBCard>
    <!-- table card -->
    <MDBCard id="ContentUploads" class="p-0">
      <MDBCardBody class="px-4 overflow-hidden pb-0" style="display: grid; grid-template-rows: auto auto auto 1fr">
        <ClaimsListLegend :is-admin="isAdminOrSuperAdmin" selected-user-id="selectedUserId" />
        <!-- table -->
        <div class="overflow-auto mt-3" ref="tableRef" id="table-container">
          <MDBTable hover class="align-middle">
            <thead class="table-dark">
              <tr>
                <th scope="col" class="py-3 align-middle text-left"
                  style="position: sticky; top: 0; padding-left: 30px; z-index: 3;">
                  Action
                </th>
                <th scope="col" class="py-3 align-middle text-left" v-if="isAdminOrSuperAdmin"
                  style="position: sticky; top: 0; padding-left: 23px;">
                  Distributor Name
                </th>
                <th v-for="(header, i) in tableHeaders" :key="i" scope="col" class="py-3 align-middle text-left"
                  style="position: sticky; top: 0; padding-left: 23px;">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody class="overflow-auto">
              <template v-if="isLoading">
                <tr>
                  <td :colspan="isAdminOrSuperAdmin ? '18' : '16'" class="text-center">
                    <MDBSpinner />
                  </td>
                </tr>
              </template>
              <template v-else>
                <template v-if="contentsList.length === 0">
                  <tr>
                    <td :colspan="isAdminOrSuperAdmin ? '18' : '16'" class="text-center">No data found.</td>
                  </tr>
                </template>
                <template v-else v-for="(contents, k) in contentsList" :key="k">
                  <ClaimsListTableRow :contents="contents" @viewContent="viewContent" @updateContent="updateContent"
                    :isAdmin="isAdminOrSuperAdmin" />
                </template>
              </template>
            </tbody>
          </MDBTable>
        </div>
        <!-- pagination -->
        <div class="d-md-flex d-block justify-content-between py-3 overflow-auto">
          <Paginate v-if="contentsList.length != 0" :page-count="contentsListPagination.totalPages"
            :click-handler="handlePage" :prev-text="'Previous'" :next-text="'Next'" :container-class="'pagination'"
            first-last-button style="margin-top: 0px; margin-left: unset; overflow: auto;">
          </Paginate>
          <!-- page size -->
          <MDBSelect v-model:options="pageSizeFilter" v-model:selected="selectedPageSize" label="Page Size" size="sm"
            class="mt-lg-0 mt-4 py-lg-1" />
        </div>
      </MDBCardBody>
    </MDBCard>
    <!-- view modal -->
    <MDBModal id="ViewContentModal" tabindex="-1" labelledby="ViewContentModal" v-model="showViewContentModal" size="lg"
      :static-backdrop="true">
      <MDBModalHeader class="px-4 py-3">
        <MDBModalTitle>View Content</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody class="px-4">
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 w-100">
            <label for="assetLabel" class="form-label">Asset Label</label>
            <input type="text" class="form-control" id="assetLabel" placeholder="Enter Asset Label"
              :value="selectedContent.assetLabel" disabled />
          </div>
          <div class="mb-3 w-100">
            <label for="assetId" class="form-label">Asset ID</label>
            <input type="text" class="form-control" id="assetId" placeholder="Enter Asset ID"
              :value="selectedContent.assetId" disabled />
          </div>
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 title w-100">
            <label for="youtubeChannel" class="form-label">Youtube Channel Name</label>
            <input type="text" class="form-control" id="youtubeChannel" :value="selectedContent.youtubeChannel"
              disabled />
          </div>
          <div class="mb-3 w-100">
            <label for="youtubeUrl" class="form-label">Youtube Channel URL</label>
            <input type="text" class="form-control" id="youtubeUrl" :value="selectedContent.youtubeUrl" disabled />
          </div>
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 w-100">
            <label for="claimStatus" class="form-label">Claim Status</label>
            <input type="text" class="form-control" id="claimStatus"
              :value="selectedContent.claimStatusName ? selectedContent.claimStatusName : '-'" disabled />
          </div>
          <div class="mb-3 w-100">
            <label for="claimant" class="form-label">Claimant</label>
            <input type="text" class="form-control" id="claimant"
              :value="selectedContent.claimant ? selectedContent.claimantFirstName + ' ' + selectedContent.claimantLastName : '-'"
              disabled>
          </div>
          <div class="mb-3 w-100">
            <label for="timeStamp" class="form-label">Time Stamp</label>
            <input type="text" class="form-control" id="timeStamp" :value="selectedContent.timeStamp" disabled>
          </div>
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 title w-100">
            <label for="clipTitle" class="form-label">Clip Title</label>
            <input type="text" class="form-control" id="clipTitle" :value="selectedContent.clipTitle" disabled>
          </div>
          <div class="mb-3 w-100">
            <label for="clipUrl" class="form-label">Clip URL</label>
            <input type="text" class="form-control" id="clipUrl" :value="selectedContent.dropboxLink" disabled>
          </div>
        </div>
        <div class="mb-3">
          <label for="internalAssetDescription" class="form-label">Internal Asset Description</label>
          <input type="text" class="form-control" id="internalAssetDescription"
            :value="selectedContent.internalAssetDescription" disabled />
        </div>
        <div class="mb-3">
          <label for="remarks" class="form-label">Remarks</label>
          <textarea class="form-control" id="remarks" :value="selectedContent.remarks" disabled rows="3" />
        </div>
        <div class="mb-3">
          <label for="notes" class="form-label">Notes</label>
          <textarea class="form-control" id="notes" :value="selectedContent.notes" disabled rows="3" />
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 w-100">
            <label for="dateAdded" class="form-label">Date Added</label>
            <input type="text" class="form-control" id="dateAdded" :value="parseDateLong(selectedContent.dateAdded)"
              disabled />
          </div>
          <div class="mb-3 w-100">
            <label for="dateUpdated" class="form-label">Date Updated</label>
            <input type="text" class="form-control" id="dateUpdated" :value="parseDateLong(selectedContent.dateUpdated)"
              disabled />
          </div>
        </div>
      </MDBModalBody>
      <MDBModalFooter class="px-4 py-3 gap-3">
        <MDBBtn @click="showViewContentModal = false"> Close </MDBBtn>
        <MDBBtn color="primary" @click="toggleUpdate(selectedContent)"> Update Content </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <!-- update modal -->
    <MDBModal id="FormContentModal" tabindex="-1" labelledby="FormContentModal" v-model="showFormContentModal" size="lg"
      :static-backdrop="true">
      <MDBModalHeader class="px-4 py-3">
        <MDBModalTitle>
          {{ isUpdateContent ? 'Update' : 'Add New' }} Content
        </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody class="px-4">
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 w-100">
            <label for="assetLabel" class="form-label">Asset Label</label>
            <input type="text" class="form-control" id="assetLabel" placeholder="Enter Asset Label"
              :value="form.assetLabel" disabled />
          </div>
          <div class="mb-3 w-100">
            <label for="assetId" class="form-label">Asset ID</label>
            <input type="text" class="form-control" id="assetId" placeholder="Enter Asset ID" :value="form.assetId"
              disabled v-if="isUser" />
            <input type="text" class="form-control" id="assetId" placeholder="Enter Asset ID" v-model="form.assetId"
              v-else />
          </div>
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 title w-100">
            <label for="youtubeChannel" class="form-label">Youtube Channel Name</label>
            <template v-if="isUser && isEditDisabled(selectedContent.claimStatus)">
              <input type="text" class="form-control" id="youtubeChannel" :value="selectedContent.youtubeChannel"
                ref="youtubeChannelInput" disabled />
            </template>
            <input v-else type="text" class="form-control" id="youtubeChannel" placeholder="Enter Youtube Channel Name"
              v-model="form.youtubeChannel" ref="youtubeChannelInput" />
          </div>
          <div class="mb-3 w-100">
            <label for="youtubeUrl" class="form-label">Youtube Channel URL</label>
            <template v-if="isUser && isEditDisabled(selectedContent.claimStatus)">
              <input type="text" class="form-control" id="youtubeUrl" :value="form.youtubeUrl" disabled />
            </template>
            <input v-else type="text" class="form-control" id="youtubeUrl" placeholder="Enter Youtube Channel URL"
              v-model="form.youtubeUrl" />
          </div>
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 w-100">
            <label for="claimStatus" class="form-label">Claim Status</label>
            <input type="text" class="form-control" id="claimStatus"
              :value="form.claimStatusName ? form.claimStatusName : '-'" disabled
              v-if="isUser && !isStatusEditableForDistributor(selectedContent.claimStatus)" />
            <template v-else>
              <select v-model="form.claimStatus" class="form-control" style="appearance: auto">
                <option v-for="(status, index) in claimStatuses(selectedContent.claimStatus)" :key="index"
                  :value="status.value">
                  {{ status.text }}
                </option>
              </select>
            </template>
          </div>
          <div class="mb-3 w-100">
            <label for="claimant" class="form-label">Claimant</label>
            <input type="text" class="form-control" id="claimant"
              :value="form.claimant ? form.claimantFirstName + ' ' + form.claimantLastName : '-'" disabled
              v-if="isUser" />
            <div style="position:relative;z-index: 4;" v-if="isAdminOrSuperAdmin">
              <input type="text" class="form-control" v-model="searchClaimant" placeholder="Select Claimant"
                @keydown="handleClaimantBackspace" />
              <MDBTable v-if="searchClaimant !== '' && !form.claimant" responsive hover border="primary"
                class="bg-white rounded-5 overflow-auto" style="position:absolute;left:0;">
                <tbody>
                  <tr v-if="claimantListLoading">
                    <td class="text-center">Loading</td>
                  </tr>
                  <template v-else>
                    <tr v-if="claimantList.length === 0">
                      <td class="text-center">No data found.</td>
                    </tr>
                    <template v-else>
                      <tr v-for="(user, index) in claimantList" :key="index" @click="() => {
                        form.claimant = user.id;
                        searchClaimant = user.firstName + ' ' + user.lastName;
                      }" style="cursor:pointer;">
                        <td>{{ user.firstName + ' ' + user.lastName }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </MDBTable>
            </div>
          </div>
          <div class="mb-3 w-100">
            <label for="timeStamp" class="form-label">Time Stamp</label>
            <template v-if="isUser && isEditDisabled(selectedContent.claimStatus)">
              <input type="text" class="form-control" id="timeStamp" :value="form.timeStamp" ref="timeStampInput"
                disabled />
            </template>
            <input v-else type="text" class="form-control" id="timeStamp" placeholder="Enter Time Stamp"
              v-model="form.timeStamp" ref="timeStampInput" />
          </div>
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 title w-100">
            <label for="clipTitle" class="form-label">Clip Title</label>
            <template v-if="isUser && isEditDisabled(selectedContent.claimStatus)">
              <input type="text" class="form-control" id="clipTitle" :value="form.clipTitle" disabled />
            </template>
            <input v-else type="text" class="form-control" id="clipTitle" placeholder="Enter Clip Title"
              v-model="form.clipTitle" />
          </div>
          <div class="mb-3 w-100">
            <label for="clipUrl" class="form-label">Clip URL</label>
            <template v-if="isUser && isEditDisabled(selectedContent.claimStatus)">
              <input type="text" class="form-control" id="clipUrl" :value="form.dropboxLink" disabled />
            </template>
            <input v-else type="text" class="form-control" id="clipUrl" placeholder="Enter Clip URL"
              v-model="form.dropboxLink" />
          </div>
        </div>
        <div class="mb-3">
          <label for="internalAssetDescription" class="form-label">Internal Asset Description</label>
          <input type="text" class="form-control" id="internalAssetDescription"
            placeholder="Enter Internal Asset Description" :value="form.internalAssetDescription" disabled />
        </div>
        <div class="mb-3">
          <label for="remarks" class="form-label">Remarks</label>
          <input type="text" class="form-control" id="remarks" :value="form.remarks" disabled v-if="isUser" />
          <template v-else>
            <select v-model="form.remarks" class="form-control" style="appearance: auto" id="remarks">
              <option disabled value="">Please select one</option>
              <option v-for="(option, index) in remarkOptions" :key="index" :value="option">
                {{ option }}
              </option>
            </select>
          </template>
        </div>
        <div class="mb-3">
          <label for="notes" class="form-label">Notes</label>
          <textarea class="form-control" id="notes" placeholder="Enter Notes" v-model="form.notes" :disabled="isUser" />
        </div>
        <div class="d-lg-flex d-block gap-4">
          <div class="mb-3 w-100">
            <label for="dateAdded" class="form-label">Date Added</label>
            <input type="text" class="form-control" id="dateAdded" :value="parseDateLong(form.dateAdded)" disabled />
          </div>
          <div class="mb-3 w-100">
            <label for="dateUpdated" class="form-label">Date Updated</label>
            <input type="text" class="form-control" id="dateUpdated" :value="parseDateLong(form.dateUpdated)"
              disabled />
          </div>
        </div>
      </MDBModalBody>
      <MDBModalFooter class="px-4 py-3 gap-3">
        <MDBBtn @click="showFormContentModal = false"> Close </MDBBtn>
        <MDBBtn color="primary" @click="submit"> {{ isUpdateContent ? 'Confirm Update' : 'Confirm Add' }} </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <!-- generate report modal -->
    <MDBModal v-model="generateReportModal" id="GenerateReportFilterModal" tabindex="-1"
      labelledby="GenerateReportFilterModal" size="lg" :static-backdrop="true">
      <MDBModalHeader class="px-4 py-3">
        <MDBModalTitle id="GenerateReportFilterModal">
          Generate Report
        </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody id="GenerateReportFilterModal" class="px-4">
        <MDBContainer fluid class="pt-3 pb-4">
          <MDBRow class="d-lg-flex d-block">
            <MDBCol class="mb-3 mb-lg-0">
              <label for="filterDateFrom" class="form-label">Date From:</label>
              <input v-model="generateReport.dateFrom" type="date" name="filterDateFrom" id="filterDateFrom"
                class="form-control" />
            </MDBCol>
            <MDBCol class="mb-3 mb-lg-0">
              <label for="filterDateTo" class="form-label">Date To:</label>
              <input v-model="generateReport.dateTo" type="date" name="filterDateTo" id="filterDateTo"
                class="form-control" />
            </MDBCol>
          </MDBRow>
          <MDBRow class="d-lg-flex d-block mt-3 mb-2">
            <MDBCol class="mb-3 mb-lg-0">
              <label for="assetLabel" class="form-label">Asset Label</label>
              <input type="text" class="form-control" id="assetLabel" placeholder="Enter Asset Label"
                v-model="generateReport.assetLabel" />
            </MDBCol>
            <MDBCol class="mb-3 mb-lg-0">
              <label for="claimStatus" class="form-label">Claim Status</label>
              <select v-model="generateReport.claimStatus" class="form-control"
                style="appearance: auto; margin-top: 1px">
                <option v-for="(status, index) in claimStatuses(generateReport.claimStatus)" :key="index"
                  :value="status.value">
                  {{ status.text }}
                </option>
              </select>
            </MDBCol>
            <MDBCol class="mb-2 mb-lg-0">
              <label for="claimant" class="form-label">Claimant</label>
              <div style="position:relative;z-index: 4;">
                <input type="text" class="form-control" v-model="searchClaimant" placeholder="Select Claimant"
                  @keydown="handleClaimantBackspace" />
                <MDBTable v-if="searchClaimant !== '' && !generateReport.claimant" responsive hover border="primary"
                  class="bg-white rounded-5 overflow-auto" style="position:absolute;left:0;">
                  <tbody>
                    <tr v-if="claimantListLoading">
                      <td class="text-center">Loading</td>
                    </tr>
                    <template v-else>
                      <tr v-if="claimantList.length === 0">
                        <td class="text-center">No data found.</td>
                      </tr>
                      <template v-else>
                        <tr v-for="(user, index) in claimantList" :key="index" @click="() => {
                          generateReport.claimant = user.id;
                          searchClaimant = user.firstName + ' ' + user.lastName;
                        }" style="cursor:pointer;">
                          <td>{{ user.firstName + ' ' + user.lastName }}</td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </MDBTable>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
      <MDBModalFooter class="px-4 py-3 gap-2">
        <MDBBtn size="sm" @click="generateReportModal = false" class="text-capitalize">Close</MDBBtn>
        <MDBBtn @click="extractReport" size="sm" color="primary" class="text-capitalize">
          Extract Report
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
    <!-- toast -->
    <MDBToast v-model="toastContentSuccess" :delay="2000" autohide position="top-right" appendToBody stacking
      width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
      <template #title> Success </template>
      Content {{ isUpdateContent ? 'Updated' : 'Added' }} Successfully!
    </MDBToast>
    <MDBToast v-model="toastSheetSuccess" :delay="2000" autohide position="top-right" appendToBody stacking
      width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
      <template #title> Success </template>
      Syncing Successful!.
    </MDBToast>
    <MDBToast v-model="toastError" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
      color="danger" text="white" icon="fas fa-check fa-lg me-2">
      <template #title> Error </template>
      Fetching Failed
    </MDBToast>
    <MDBToast v-model="toastErrorUpdate" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
      color="danger" text="white" icon="fas fa-check fa-lg me-2">
      <template #title> Error </template>
      <ul v-if="toastErrorUpdateMessage">
        <li v-for="(message, index) in toastErrorUpdateMessage" :key="index">{{ message }}</li>
      </ul>
    </MDBToast>
    <MDBToast v-model="generateReportToast" :delay="2000" autohide position="top-right" appendToBody stacking
      width="350px" :color="generateReportToastType === 'Error' ? 'danger' : 'success'" text="white"
      icon="fas fa-check fa-lg me-2">
      <template #title> {{ generateReportToastType }} </template>
      {{ generateReportToastMessage }}
    </MDBToast>
  </VVVLayout>
</template>

<script setup>
import VVVLayout from "@/components/VVV/VVVLayout.vue";
import ClaimsListLegend from "./ClaimsListLegend.vue";
import {
  MDBContainer, MDBCard, MDBCardBody, MDBTable, MDBSpinner, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBIcon, MDBBtn, MDBToast, MDBInput, MDBSelect, MDBCol, MDBRow
} from "mdb-vue-ui-kit";
import { useTitle, useDebounceFn } from "@vueuse/core";
import { computed, onMounted, ref, watch, nextTick, watchEffect, onBeforeUnmount } from "vue";
import { GetContentsList } from "@/services/Contents/GetContentsList";
import { PostContent } from "@/services/Contents/PostContent";
import { PutContent } from "@/services/Contents/PutContent";
import { GetGoogleSheetData } from "@/services/Contents/GetGoogleSheetData";
import { GetExportExcel } from "@/services/Contents/GetExportExcel";
import { GetUsers } from '@/services/Users/GetUsers';
import ClaimsListTableRow from "./ClaimsListTableRow.vue";
import { useUserDetailStore } from "@/store/userDetailStore";
import Paginate from "vuejs-paginate-next";
import axios from "axios";
import { useToastStore } from "@/store/toastStore";
import { storeToRefs } from "pinia";
import { parseDateLong, isDateInputValid } from "@/helpers/parseDate";
import { useRouter, useRoute } from "vue-router";
import { useYoutubeStore } from "@/store/youtubeStore";

const userDetailStore = useUserDetailStore();
const { userDetail, isAdmin, isUser, isSuperAdmin } = storeToRefs(userDetailStore);
const youtubeStore = useYoutubeStore();
const { isAdded } = storeToRefs(youtubeStore);

const isAdminOrSuperAdmin = computed(() => isAdmin.value || isSuperAdmin.value);

const tableHeaders = computed(() => {
  return [
    "Channel Name",
    "Youtube URL",
    "Timestamp",
    "Asset Label",
    "Clip URL",
    "Internal Asset Description",
    "Clip Title",
    "Claim Status",
    "Asset ID",
    "Date Claimed",
    "Claimant",
    "Remarks",
    "Notes",
    "Date Added",
    "Date Updated",
  ];
});

const isEditDisabled = (claimStatus) => {
  const disabledValues = ["1", "3", "6", "7"];
  return disabledValues.includes(claimStatus);
}

const isStatusEditableForDistributor = (currentStatus) => {
  return currentStatus === '2' || currentStatus === '7'
}

const claimStatuses = (currentStatus) => {
  const options = [
    { text: "Yes", value: '1' },
    { text: "No", value: '2' },
    { text: "Previously Claimed", value: '3' },
    { text: "Fixed", value: '4' },
    { text: "Not Yet", value: '5' },
    { text: "For Verification", value: '6' },
    // { text: "Awaiting for 100 Views", value: '7' }, // Remove temporarily
    { text: "Awaiting for at least 1 view", value: '8' },
  ];

  if (isUser.value && (isStatusEditableForDistributor(currentStatus))) {
    const currentOption = options.find(option => option.value === currentStatus);
    return [
      currentOption,
      { text: "Fixed", value: '4' },
    ];
  }

  return options;
}

const remarkOptions = ref([
  "None",
  "Clip embedded is not related to the compilation",
  "Dropbox link/clip provided is not included in the compilation/wrong timestamp",
  "Dropbox link unavailable",
  "Clip contains YT policy restriction",
  "Compilation contains YT policy restriction",
  "Manually claiming duet/stitch/ compilation is prohibited",
  "Video Unavailable",
  "Previously Claimed",
  "Must use less than 25 secs clip",
  "Channel on allowlist/whitelisted",
  "Compilation of single unlicensed creator is prohibited",
  "Incomplete Details",
  "Channel is registered to one of our distribution partners",
  "Should have at least 1 view",
  "Improper clip placement",
  "Video should not be unlisted",
]);

const claimStatusFilter = ref([
  { text: "All", value: '' },
  { text: "Yes", value: '1' },
  { text: "No", value: '2' },
  { text: "Previously Claimed", value: '3' },
  { text: "Fixed", value: '4' },
  { text: "Not Yet", value: '5' },
  { text: "For Verification", value: '6' },
  // { text: "Awaiting for 100 Views", value: '7' }, // Remove temporarily
  { text: "Awaiting for at least 1 view", value: '8' },
]);

const selectedFilter = ref('');
const searchFilter = ref('');
const isLoading = ref(false);
const contentsList = ref([]);
const contentsListPagination = ref({});

const selectedPageSize = ref();
const pageSizeFilter = ref([
  { text: "10", value: 10 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
]);

watch(selectedPageSize, (pageSizeValue) => {
  if (pageSizeValue !== null) {
    fetchContentsList({ pageNumber: 1 });
  }
});

watch(selectedFilter, (filterValue) => {
  if (filterValue !== undefined) {
    fetchContentsList({ pageNumber: 1 });
    updateClaimStatusRoute();
  }
});

watch(searchFilter, (searchFilterValue) => {
  if (searchFilterValue !== '') {
    fetchContentsList({ pageNumber: 1 });
    updateSearchContentRoute();
  }
});

const handleBackspaceContent = (event) => {
  if (event.key === 'Backspace' && searchFilter.value) {
    searchFilter.value = '';
    fetchContentsList({ pageNumber: 1 })
    updateSearchContentRoute();
  }
}

onMounted(() => {
  fetchContentsList({ pageNumber: 1 });
  useTitle(`${isAdminOrSuperAdmin.value ? 'Claims List' : 'My Claims'} | Very Viral Visuals`);
  if (isAdded.value) {
    toastContentSuccess.value = true;
  }
});

watch(isAdminOrSuperAdmin, () => {
  useTitle(`${isAdminOrSuperAdmin.value ? 'Claims List' : 'My Claims'} | Very Viral Visuals`);
  if (isAdminOrSuperAdmin.value) {
    getUsers();
  }
});

const scrollToTable = () => {
  nextTick(() => {
    const element = document.getElementById('ContentUploads');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  });
};

const fetchContentsList = async ({ pageNumber }) => {
  isLoading.value = true;
  const params = { pageNumber: pageNumber, rowsPerPage: selectedPageSize.value, claimStatus: selectedFilter.value, searchString: searchFilter.value || searchAssetLabel.value };
  if (isAdminOrSuperAdmin.value) {
    params.userId = selectedUserId.value;
  }
  try {
    const response = await GetContentsList(params);
    contentsList.value = response.data;
    contentsListPagination.value = response.pagination;
    scrollToTable();
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const showViewContentModal = ref(false);
const selectedContent = ref({});
const viewContent = (content) => {
  showViewContentModal.value = true;
  selectedContent.value = content;
};

const handlePage = (currentPage) => {
  event.preventDefault();
  if (isUser.value) fetchContentsList({ pageNumber: currentPage, userId: userDetail.value.id });
  else fetchContentsList({ pageNumber: currentPage, userId: selectedUserId.value });
}

const form = ref({
  youtubeChannel: "",
  youtubeUrl: "",
  timeStamp: "",
  clipTitle: "",
  claimStatus: "",
  claimStatusName: "",
  claimant: "",
  dropboxLink: "",
  assetId: "",
  assetLabel: "",
  internalAssetDescription: "",
  remarks: "",
  notes: "",
  dateAdded: "",
  dateUpdated: "",
});

const showFormContentModal = ref(false);
const toggleAdd = () => {
  showFormContentModal.value = true;
  isUpdateContent.value = false;
}

watch(showFormContentModal, (newShowFormContentModal) => {
  if (!newShowFormContentModal) {
    resetForm();
    searchClaimant.value = '';
    claimantList.value = [];
  }
});

const claimStatusBreakdown = ref();
const toastErrorUpdate = ref(false);
const toastErrorUpdateMessage = ref([]);

const submit = async () => {
  try {
    let response;
    if (isUpdateContent.value) {
      response = await PutContent({ ...form.value, ...(isAdminOrSuperAdmin.value ? { userId: selectedUserId.value } : {}) });
    } else {
      response = await PostContent({ ...form.value, ...(isAdminOrSuperAdmin.value ? { userId: selectedUserId.value } : {}) });
    }
    if (response && response.message) {
      let errorMessages = [];
      for (let key in response.errors) {
        errorMessages = errorMessages.concat(response.errors[key]);
      }
      toastErrorUpdate.value = true;
      toastErrorUpdateMessage.value = errorMessages;
      setTimeout(() => {
        toastErrorUpdate.value = false;
      }, 3000);
      return;
    }
    showFormContentModal.value = false;
    toastContentSuccess.value = true;
    if (isUser.value) fetchContentsList({ pageNumber: contentsListPagination.value.currentPage, userId: userDetail.value.id });
    else fetchContentsList({ pageNumber: contentsListPagination.value.currentPage, userId: selectedUserId.value });
    claimStatusBreakdown.value.loadBreakdown();
  } catch (error) {
    const errorMessage = error.data.message;
    const capitalizedErrorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
    toastErrorUpdate.value = true;
    toastErrorUpdateMessage.value = [capitalizedErrorMessage];
    setTimeout(() => {
      toastErrorUpdate.value = false;
    }, 3000);
    return;
  }
}

const resetForm = () => {
  form.value = {
    youtubeChannel: "",
    youtubeUrl: "",
    timeStamp: "",
    clipTitle: "",
    claimStatus: "",
    claimant: "",
    dropboxLink: "",
    assetId: "",
    assetLabel: "",
    internalAssetDescription: "",
    remarks: "",
    notes: "",
  }
}

const isUpdateContent = ref(false);
const updateContent = (content) => {
  if (content.claimant) { searchClaimant.value = content.claimantFirstName + ' ' + content.claimantLastName }
  form.value = { ...content };
  selectedContent.value = { ...content };
  isUpdateContent.value = true;
  showFormContentModal.value = true;
}

const toastContentSuccess = ref(false)
watch(toastContentSuccess, (newToastSuccess) => {
  if (!newToastSuccess) {
    isUpdateContent.value = false;
    isAdded.value = false;
  }
});

const toastError = ref(false);
const toastSheetSuccess = ref(false);
const syncGoogleSheet = async (id) => {
  if (toastSheetSuccess.value) toastSheetSuccess.value = false;
  isLoading.value = true;
  try {
    await GetGoogleSheetData(id);
    isLoading.value = false;
    toastSheetSuccess.value = true;
  } catch (error) {
    isLoading.value = false;
    toastError.value = true;
  }
}

const userList = ref([]);
const userListLoading = ref(false);
const toastStore = useToastStore();
const tableRef = ref(null);
const getUsers = useDebounceFn(async (searchVal = '') => {
  userListLoading.value = true;
  try {
    const response = await axios.get("api/users", {
      params: { SearchString: searchVal, UserTypeId: 3 },
    });
    userList.value = response.data.data;
    userListLoading.value = false;
  } catch (error) {
    userListLoading.value = false;
    toastStore.toastObject = {
      ...toastStore.toastObject,
      ...toastStore.toastStatus.fail,
    };
  }
}, 200);

const searchAssetLabel = ref('');
watch(searchAssetLabel, (newSearch) => {
  if (newSearch === '') {
    assetLabelList.value = [];
  } else {
    getAssetLabel(newSearch.toLowerCase());
  }
});

const search = ref('');
watch(search, (newSearch) => {
  if (newSearch === '') {
    userList.value = [];
  } else {
    getUsers(newSearch.toLowerCase());
  }
});

const selectedUserId = ref(false);
const selectUser = (user) => {
  search.value = user.firstName + ' ' + user.lastName;
  selectedUserId.value = user.id;
  fetchContentsList({ pageNumber: 1 })
  updateDistributorRoute();
}

const handleBackspaceDistributor = (event) => {
  if (event.key === 'Backspace' && selectedUserId.value) {
    search.value = '';
    selectedUserId.value = null;
    fetchContentsList({ pageNumber: 1 })
    updateDistributorRoute();
  }
}

const handleBackspaceAssetlabel = (event) => {
  if (event.key === 'Backspace' && selectedAssetlabel.value) {
    searchAssetLabel.value = '';
    selectedAssetlabel.value = false;
    fetchContentsList({ pageNumber: 1 });
    updateAssetLabelRoute();
  }
}

const searchClaimant = ref('');
const claimantList = ref([]);
const claimantListLoading = ref(false);
const getClaimantList = useDebounceFn(async (searchVal = '') => {
  claimantListLoading.value = true;
  try {
    const response = await axios.get("api/users?userTypeId=1&userTypeId=4", {
      params: { SearchString: searchVal }
    });
    claimantList.value = response.data.data;
    claimantListLoading.value = false;
  } catch (error) {
    claimantListLoading.value = false;
    toastStore.toastObject = {
      ...toastStore.toastObject,
      ...toastStore.toastStatus.fail,
    };
  }
}, 200);

watch(searchClaimant, (newSearch) => {
  if (newSearch === '') {
    claimantList.value = [];
  } else {
    getClaimantList(newSearch.toLowerCase());
  }
});

const timeStampInput = ref();
const youtubeChannelInput = ref();
const toggleUpdate = (selectedContent) => {
  updateContent(selectedContent);
  showViewContentModal.value = false;
}

watch(showFormContentModal, (newShowFormContentModal) => {
  if (newShowFormContentModal) {
    if (isAdminOrSuperAdmin.value) {
      setTimeout(() => {
        timeStampInput.value.focus();
      }, 200);
    }
    if (isUser.value) {
      setTimeout(() => {
        youtubeChannelInput.value.focus();
      }, 200);
    }
  }
});

const handleClaimantBackspace = () => {
  if (event.key === 'Backspace' && form.value.claimant) {
    form.value = { ...form.value, claimant: '' };
    searchClaimant.value = '';
    claimantList.value = [];
  }
}

const generateReport = ref({
  dateFrom: "",
  dateTo: "",
  assetLabel: "",
  claimStatus: "",
  claimant: ""
});

const generateReportModal = ref(false);
watch(generateReportModal, (newGenerateReportModal) => {
  if (!newGenerateReportModal) {
    generateReport.value = {
      dateFrom: "",
      dateTo: "",
      assetLabel: "",
      claimStatus: "",
      claimant: ""
    }
    searchClaimant.value = '';
  }
});

const generateReportToast = ref(false);
const generateReportToastType = ref("Error");
const generateReportToastMessage = ref("");

const showExportToast = (type, message) => {
  generateReportToast.value = true;
  generateReportToastType.value = type;
  generateReportToastMessage.value = message;
}

const extractReport = async () => {
  const reportData = generateReport.value;
  const { dateFrom, dateTo, assetLabel, claimStatus, claimant } = reportData;

  if (!validateExportFields({ dateFrom, dateTo, assetLabel, claimStatus, claimant })) {
    return;
  }

  const { url } = await GetExportExcel(reportData);
  showExportToast('Success', 'Download Successfully!');
  generateReportModal.value = false;
  window.open(url, '_blank');
};

const validateExportFields = ({ dateFrom, dateTo, assetLabel, claimStatus, claimant }) => {
  const fields = { dateFrom, dateTo, assetLabel, claimStatus, claimant };
  const filledField = Object.values(fields).some(value => value !== "" && value !== null);

  if (!filledField) {
    showExportToast('Error', 'At least one filter must be applied.');
    return false;
  }

  if ((!dateFrom && dateTo) || (dateFrom && !dateTo)) {
    showExportToast('Error', 'Both "Date From" and "Date To" must be filled.');
    return false;
  }

  if (dateFrom && !isDateInputValid(dateFrom)) {
    showExportToast('Error', 'Invalid "Date From". Please use a valid format for MM/DD/YYYY.');
    return false;
  }

  if (dateTo && !isDateInputValid(dateTo)) {
    showExportToast('Error', 'Invalid "Date To". Please use a valid format for MM/DD/YYYY.');
    return false;
  }

  if (dateFrom && dateTo && new Date(dateTo) <= new Date(dateFrom)) {
    showExportToast('Error', 'Ensure "Date To" is after "Date From".');
    return false;
  }

  return true;
};

const router = useRouter();
const route = useRoute();

const updateClaimStatusRoute = () => {
  if (selectedFilter.value) {
    router.push({
      path: '/ClaimsList',
      query: { claimStatus: selectedFilter.value }
    });
  } else {
    router.push({
      path: "/ClaimsList",
      name: "ClaimsList",
    })
  }
};

if (route.query.claimStatus) {
  selectedFilter.value = route.query.claimStatus;
}

watch(() => route.query.claimStatus, (claimStatusValue) => {
  if (claimStatusValue !== undefined && claimStatusValue !== null && selectedFilter.value !== claimStatusValue) {
    selectedFilter.value = claimStatusValue;
    fetchContentsList({ pageNumber: 1 });
    updateClaimStatusRoute();
  }
});

const updateSearchContentRoute = () => {
  if (searchFilter.value) {
    router.push({
      path: '/ClaimsList',
      query: { searchContent: searchFilter.value }
    });
  } else {
    router.push({
      path: "/ClaimsList",
      name: "ClaimsList",
    })
  }
};

if (route.query.searchContent) {
  searchFilter.value = route.query.searchContent;
}

watch(() => route.query.searchContent, (searchValue) => {
  if (searchValue !== undefined && searchValue !== null && searchFilter.value !== '') {
    searchFilter.value = searchValue;
    fetchContentsList({ pageNumber: 1 });
    updateSearchContentRoute();
  }
});

const updateDistributorRoute = () => {
  if (selectedUserId.value) {
    router.push({
      path: '/ClaimsList',
      query: { distributor: selectedUserId.value }
    });
  } else {
    router.push({
      path: "/ClaimsList",
      name: "ClaimsList",
    })
  }
};

if (route.query.distributor) {
  selectedUserId.value = route.query.distributor;
}

watch(() => route.query.distributor, (userId) => {
  if (userId !== undefined && userId !== null && selectedUserId.value !== userId) {
    GetUsers(userId)
      .then(user => {
        search.value = user.firstName + ' ' + user.lastName;
        selectedUserId.value = user.id;
        fetchContentsList({ pageNumber: 1 });
        updateDistributorRoute();
      });
  } else {
    search.value = '';
    selectedUserId.value = null;
  }
});

const updateAssetLabelRoute = () => {
  if (searchAssetLabel.value) {
    router.push({
      path: '/ClaimsList',
      query: { assetLabel: searchAssetLabel.value }
    });
  } else {
    router.push({
      path: "/ClaimsList",
      name: "ClaimsList",
    })
  }
};

if (route.query.assetLabel) {
  searchAssetLabel.value = route.query.assetLabel;
}

watch(() => route.query.assetLabel, (assetLabelSearch) => {
  if (assetLabelSearch !== undefined && assetLabelSearch !== null && searchAssetLabel.value !== '') {
    searchAssetLabel.value = assetLabelSearch;
    fetchContentsList({ pageNumber: 1 });
    updateAssetLabelRoute();
  }
});

const isMobile = ref(window.innerWidth <= 767);
const contentInputWidth = ref('');
const distriInputStyle = ref('');
const assetLabelInputStyle = ref('');

const updateResolution = () => {
  isMobile.value = window.innerWidth <= 767;
};

watchEffect(() => {
  contentInputWidth.value = isMobile.value ? { width: '100%' } : (isUser.value ? { width: '31.5%' } : { width: '17.6%' });
  assetLabelInputStyle.value = isMobile.value ? { position: 'relative', zIndex: 4 }
    : { position: 'relative', zIndex: 4, width: '19.5%' };
  distriInputStyle.value = isMobile.value ? { position: 'relative', zIndex: 4 }
    : { position: 'relative', zIndex: 4, width: '29%' };
});

onMounted(() => {
  window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateResolution);
});

const assetLabelList = ref([]);
const assetLabelListLoading = ref(false);
const getAssetLabel = useDebounceFn(async (searchVal = '') => {
  assetLabelListLoading.value = true;
  try {
    const response = await axios.get("api/assetLabels/List", {
      params: { SearchString: searchVal },
    });
    assetLabelList.value = response.data.data;
    assetLabelListLoading.value = false;
  } catch (error) {
    assetLabelListLoading.value = false;
    toastStore.toastObject = {
      ...toastStore.toastObject,
      ...toastStore.toastStatus.fail,
    };
  }
}, 200);

const selectedAssetlabel = ref(false);
const selectAssetLabel = (assetlabel) => {
  searchAssetLabel.value = assetlabel.name;
  selectedAssetlabel.value = true;
  fetchContentsList({ pageNumber: 1 });
  updateAssetLabelRoute();
}
</script>

<style scoped>
.title {
  width: 47.5%;
}

.btn-primary {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  gap: 5px;
}

.content-title {
  font-weight: bold;
  width: 200px;
  text-align: right;
  padding-right: 10px;
}

.content-info {
  width: 200px;
  text-align: left;
  padding-left: 10px;
}

.pagination {
  margin-top: 2rem;
  margin-bottom: 0;
  margin-left: auto;
}

#actions {
  flex: 0 0 55px;
}

#ContentUploads {
  flex: 1 1 auto;
  overflow: hidden;
}

#table-container {
  flex: 1 1 auto;
  max-height: 65vh;
}

.claim-status-select {
  width: fit-content;
}

/* customized select with floating label */
.did-floating-label-content {
  position: relative;
}

.did-floating-label {
  color: #1e4c82;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 8.2px;
  top: 11px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-input,
.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 28.5px;
  padding: 0 13px;
  padding-right: 10px;
  background: #fff;
  color: #6c757d;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  box-sizing: border-box;

  &:focus {
    outline: 1.5px solid #0d6efd;

    ~.did-floating-label {
      color: #0d6efd;
      top: -8px;
      font-size: 10px;
    }
  }
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
  top: -8px;
  font-size: 10px;
}

.did-floating-select:not([value=""]):valid~.did-floating-label {
  top: -8px;
  font-size: 10px;
}

.did-floating-select[value=""]:focus~.did-floating-label {
  top: 11px;
  font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

td {
  text-wrap: nowrap;
  font-size: 13px;
}

th {
  text-wrap: nowrap;
  max-width: 30%;
  font-size: 13px;
  z-index: 0;
}

.btn {
  font-size: 10px;
  padding: 6.5px 14px;
}

@media screen and (max-width: 767px) {

  #actions {
    flex: 0 0 auto;
  }

  #ContentUploads {
    flex: 0 0 auto;
    overflow: hidden;
  }

  #table-container {
    flex: 0 0 auto;
  }

  #table-container-header {
    z-index: 0;
  }

  .claim-status-select {
    width: 100%;
  }

  .did-floating-input,
  .did-floating-select {
    width: 100%;
  }
}
</style>
